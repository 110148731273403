<template>
  <div class="px-10">
    <v-row>
      <v-col cols="12">
        <div class="text-h4 primary--text mb-5">ตั้งค่าเอกสาร</div>
      </v-col>
      <!--  <v-col cols="2" class="pl-15"
        ><h3 class="error--text">อย่าลืมเลือกสาขานะ</h3></v-col
      >
    <v-col cols="4">
        <v-select
          v-model="branch_id"
          label="เลือกสาขา"
          outlined
          :items="branchs"
          item-value="_id"
          item-text="branch_name"
          dense
          @change="
            getBranch();
            getThirdinsurance();
          "
        ></v-select>
      </v-col> -->
    </v-row>
    <v-divider class="mb-6" />

    <div class="text-h4 primary--text mb-5">เอกสาร</div>
    <v-row>
      <v-col cols="6">
        <div>
          แสดงค่าปรับภาษีรถ แยกต่างหาก
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>ระบบจะแสดงค่าปรับภาษีแยกให้ลูกค้าดู ตอนปริ้นเอกสาร เช่น ค่าภาษี 100 บาท ค่าปรับ 6 บาท เป็นต้น</span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_late_tax_fine"
        >
          <v-radio name="" label="แสดงค่าปรับภาษี" :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดงค่าปรับ" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงจำนวนเงิน ที่รับมา
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>แสดงเงินที่รับมา ในใบเสร็จรับเงิน</span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_payment_amount"
        >
          <v-radio name="" label="แสดงเงินที่รับมา" :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงจำนวน เงินทอน
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>แสดงเงินทอน ในใบเสร็จรับเงิน</span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_remaining_amount"
        >
          <v-radio name="" label="แสดงเงินทอน" :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
        </v-radio-group>

        <div>
          ต้องอนุมัติการแก้ไขเอกสาร
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>ต้องให้เจ้าของอนุมัติการแก้ไขเอกสาร ถ้าไม่อนุมัติเอกสารก็จะมีข้อมูลเหมือนเดิม</span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.document_approvement"
        >
          <v-radio name="" label="ต้องอนุมัติ" :value="true"></v-radio>
          <v-radio name="" label="ไม่ต้อง แก้ไขเองได้เลย" :value="false"></v-radio>
        </v-radio-group>
        <div v-if="this.form.setting.document_approvement == true">
          เลือกกลุ่มผู้ใช้ที่มีสิทธิ์อนุมัติ
          <v-col cols="6"
            ><v-combobox
              multiple
              outlined
              small-chips
              label="เลือกกลุ่มผู้ใช้ที่มีสิทธิ์อนุมัติ"
              width="100px"
              cols="3"
              v-model="form.setting.user_group_approve"
              item-value="_id"
              item-text="group_name"
              :items="list.user_groups"
            ></v-combobox
          ></v-col>
        </div>
      </v-col>

      <v-col cols="6">
        <div>
          แสดงวันคุ้มครองของ พ.ร.บ. ตอนปริ้นเอกสาร
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_3rd_insurance_protection_date"
        >
          <v-radio name="" label="แสดงวันพ.ร.บ." :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงวันคุ้มครองของ ประกันภัย ตอนปริ้นเอกสาร
          </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_insurance_protection_date"
        >
          <v-radio name="" label="แสดงวันประกันภัย" :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงเบอร์โทรลูกค้าในใบเสร็จ ตอนปริ้นเอกสาร
          </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_customer_phone_number"
        >
          <v-radio name="" label="แสดงเบอร์โทร" :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงชื่อโปรเจค ตอนปริ้นเอกสาร
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_project_name"
        >
          <v-radio name="" label="แสดงชื่อโปรเจค" :value="true"></v-radio>
          <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-card width="100%">
          <!-- <v-card-title class="light">
            <v-row justify="space-around">
              <v-col cols="3" class="text-left">เอกสาร</v-col>
              <v-col cols="3" class="text-center">หัวเอกสาร</v-col>
              <v-col cols="3" class="text-center">หมายเหตุ</v-col>
            </v-row>
          </v-card-title> -->

          <v-card-text class="pt-5">
            <v-row>
              <v-col cols="2" class="primary--text text-h6 text-right"
                >หมายเหตุท้ายเอกสาร</v-col
              >
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.quotation.header" v-model="form.document_setting.quotation.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col class="text-center" cols="4">
                <v-textarea
                  name="document_setting.quotation.note"
                  v-model="form.document_setting.quotation.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
            </v-row>
          </v-card-text>
          <!--
          <v-divider class="mx-15" />

         <v-card-text>
            <v-row justify="space-around">
              <v-col cols="3" class="primary--text text-h6">ใบแจ้งหนี้</v-col>
               <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.invoice.header" v-model="form.document_setting.invoice.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> 
              <v-col cols="3" class="text-center">
                <v-textarea
                  name="document_setting.invoice.note"
                  v-model="form.document_setting.invoice.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="3" class="primary--text text-h6"
                >ใบเสร็จรับเงิน</v-col
              >
              <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.receipt.header" v-model="form.document_setting.receipt.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col>
              <v-col cols="3" class="text-center">
                <v-textarea
                  name="document_setting.receipt.note"
                  v-model="form.document_setting.receipt.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="3" class="primary--text text-h6">ใบลดหนี้</v-col>
              <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.credit_note.header" v-model="form.document_setting.credit_note.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col>
              <v-col cols="3" class="text-center">
                <v-textarea
                  name="document_setting.credit_note.note"
                  v-model="form.document_setting.credit_note.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="3" class="primary--text text-h6">ใบเพิ่มหนี้</v-col>
              <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.debit_note.header" v-model="form.document_setting.debit_note.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col>
              <v-col cols="3" class="text-center">
                <v-textarea
                  name="document_setting.debit_note.note"
                  v-model="form.document_setting.debit_note.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="3" class="primary--text text-h6"
                >ใบกำกับภาษี/ใบเสร็จ (สำหรับบริษัทที่แยกบิล)</v-col
              >
              <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.tax_invoice.header" v-model="form.document_setting.tax_invoice.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col>
              <v-col cols="3" class="text-center">
                <v-textarea
                  name="document_setting.tax_invoice.note"
                  v-model="form.document_setting.tax_invoice.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
            </v-row>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      document_setting: {
        credit_note: { header: '', note: '' },
        debit_note: { header: '', note: '' },
        invoice: { header: '', note: '' },
        quotation: { header: '', note: '' },
        receipt: { header: '', note: '' },
        tax_invoice: { header: '', note: '' },
      },
      setting: {
        show_late_tax_fine: false,
        show_payment_amount: false,
        show_remaining_amount: false,
        show_3rd_insurance_protection_date: false,
        show_insurance_protection_date: false,
        show_customer_phone_number: true,
        document_approvement: false,
        user_group_approve: [],
      },
    },
    list: {
      user_groups: []
    },
    loading: false,
    branch_id: 0,
    branchs: [],
  }),
  created() {
    this.branch_id = this.$store.state.selected_branch._id;
    this.getBranch();
    this.getUserGroups();
  },
  methods: {
    close() {
      this.$router.push({ name: 'setting-business-branch' });
    },
    getUserGroups() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true })
      };
      this.$axios
        .post(`${this.$baseUrl}/user_group/get_user_group_list`, body)
        .then(async res => {
          this.list.user_groups = res.result;
          console.log(res.result)
        });
    },
    getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          this.branchs = res.result;
          if (this.branchs.length > 0) {
            this.getBranch();
          } else this.loading = true;
        });
    },
    async getBranch() {
      let body = {
        token: this.$jwt.sign({ branch_id: this.branch_id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          console.log(this.form);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            this.form = { branch_id: this.form._id, ...this.form };
            delete this.form._id;
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };

            await this.$axios
              .post(`${this.$baseUrl}/branch/edit_branch`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log('save()');
          }
          this.loading = false;
        }
      );
    },
  },
  computed: {},
};
</script>
